import React, { useEffect, useState } from 'react';
import { dashboardNavigations } from '../../../sideMenuNavigation';
import clsx from 'clsx';
import { NavLink, useLocation } from 'react-router-dom';
import Tooltip from '@clipmx/clip-storybook/dist/components/Tooltip';
import Typography from '@clipmx/clip-storybook/dist/components/Typography';
import Icons from '../Icons';
import useMediaQuery from '@clipmx/clip-storybook/dist/hooks/useMediaQuery';
import useTheme from '@clipmx/clip-storybook/dist/hooks/useTheme';
import { useTracking } from '../../../hooks/useTracking';

export const useAppSidebar = () => {
  const theme = useTheme();
  const isMobil = useMediaQuery(theme.breakpoints.down('xs'));
  const [openSideBar, setOpenSideBar] = useState(true);
  const { pathname: pathNameActive } = useLocation();
  const { analitics, getDeviceType, merchant } = useTracking();

  const localLinks = dashboardNavigations.filter(element => !element.isExternalLink);
  const externalLinks = dashboardNavigations.filter(element => !element.items && element.isExternalLink);

  const removeSpaces = text => text.replace(/ /g, '');

  const subMenusStates = localLinks.reduce((prevValue, currentValue) => {
    if (currentValue?.items?.some(item => item.path === pathNameActive)) {
      const name = removeSpaces(currentValue.name);
      return {
        ...prevValue,
        [name]: true,
      };
    }
    return prevValue;
  }, {});

  const [statesSubmenus, setStatesSubmenus] = useState(subMenusStates);

  const resetToggleSubmenus = () => setStatesSubmenus({});

  const getPropsIconType = ({ icon, clipIcon, customIcon }) => {
    let propsIcon = null;

    if (!!customIcon) {
      propsIcon = { type: 'custom', nameCustomIcon: customIcon };
    } else if (!!clipIcon) {
      propsIcon = { type: 'clipIcon', iconName: clipIcon };
    } else if (!!icon) {
      propsIcon = { type: 'icon', iconName: icon };
    }
    return propsIcon ?? {};
  };

  const returnPanel = path => {
    window.location.replace(path);
  };

  const ItemNavBar = props => {
    const {
      name,
      path,
      icon,
      clipIcon,
      customIcon,
      isExternalLink = false,
      replaceHistory = false,
      items,
      categoryName,
      nameMetric,
    } = props;

    const isCategory = !!items;
    const isCategoryLink = !!categoryName;
    const propsIcon = getPropsIconType({ icon, clipIcon, customIcon });
    const validPropsIcon = Object.keys(propsIcon).length > 0;
    const isBlankRedirect = isExternalLink && !replaceHistory;

    return isCategory ? (
      <CategoryLinks category={props} />
    ) : (
      <Tooltip
        disableHoverListener={openSideBar || isCategoryLink}
        disableFocusListener
        disableTouchListener
        title={name}
        placement="right"
        arrow
      >
        <NavLink
          to={{ pathname: path }}
          className="container-link"
          onClick={() => {
            if (replaceHistory) {
              returnPanel(path);
              return;
            }
            if (!isMobil && (openSideBar === false || (openSideBar && isCategoryLink === false))) {
              resetToggleSubmenus();
            }
            if (openSideBar && isMobil) {
              setOpenSideBar(false);
            }
            analitics.trackEvent({
              event: 'Developers Navigation Opened',
              properties: {
                merchant: merchant,
                device: getDeviceType(),
                navigation: `${nameMetric ?? name.toLocaleLowerCase().replace(' ', '_')}_clicked`,
              },
            });
          }}
          {...(isBlankRedirect && { target: '_blank' })}
        >
          {validPropsIcon && <Icons {...propsIcon} className={clsx('icon-link', !!clipIcon && 'icon-clip')} />}
          <span className="text-link">{name}</span>
        </NavLink>
      </Tooltip>
    );
  };

  const WrapCategoryLinks = ({ children, isShowSubmenu = true, lengthItems }) => {
    const marginBottomItemLink = 12;
    const heightWrapItemsToggle = lengthItems * (36 + marginBottomItemLink) - marginBottomItemLink;

    return openSideBar || isMobil ? (
      <div
        style={{ height: `${heightWrapItemsToggle}px` }}
        className={clsx('items-toggle', isShowSubmenu ? '' : 'hidden')}
      >
        {children}
      </div>
    ) : (
      <div className={clsx('popover', isShowSubmenu && 'show')}>
        <div className="container-children">{children}</div>
        <div className="background-popover"></div>
      </div>
    );
  };

  const CategoryLinks = ({ category }) => {
    const nameWithoutSpaces = removeSpaces(category.name);
    const isShowSubmenu = !!statesSubmenus[nameWithoutSpaces];
    const propsIcon = getPropsIconType(category);
    const isCategoryActive = category.items.some(item => item.path === pathNameActive) && openSideBar === false;

    return (
      <div className={clsx('wrap-toggle', !openSideBar && 'collapsed')}>
        <Tooltip
          disableFocusListener
          disableTouchListener
          disableHoverListener={openSideBar}
          title={category.name}
          placement="right"
          arrow
        >
          <div
            className={clsx('category-name', isCategoryActive && 'active')}
            onClick={() => {
              resetToggleSubmenus();
              setStatesSubmenus({
                [nameWithoutSpaces]: !statesSubmenus[nameWithoutSpaces],
              });
            }}
          >
            <Icons {...propsIcon} className="icon-link" />
            <Typography>{category.name}</Typography>
            {openSideBar && (
              <Icons type="icon" iconName={isShowSubmenu ? 'expand_less' : 'expand_more'} className="icon-arrow" />
            )}
          </div>
        </Tooltip>
        <WrapCategoryLinks isShowSubmenu={isShowSubmenu} lengthItems={category.items.length}>
          {category.items.map(link => {
            return <ItemNavBar key={removeSpaces(link.name)} {...link} categoryName={category.name} />;
          })}
        </WrapCategoryLinks>
      </div>
    );
  };

  useEffect(() => {
    if (isMobil && openSideBar) {
      setOpenSideBar(false);
    }
    if (!isMobil && openSideBar === false) {
      setOpenSideBar(true);
    }
    // eslint-disable-next-line
  }, [isMobil]);

  return {
    props: {
      localLinks,
      externalLinks,
      openSideBar,
      isMobil,
    },
    methods: {
      setOpenSideBar,
      removeSpaces,
    },
    microcomponents: {
      ItemNavBar,
    },
  };
};
