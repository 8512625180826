import useMediaQuery from '@clipmx/clip-storybook/dist/hooks/useMediaQuery';
import useTheme from '@clipmx/clip-storybook/dist/hooks/useTheme';
import { useLocation, useHistory } from 'react-router-dom';
import { DialogContext } from '@clipmx/clip-storybook/dist/components/Dialog/dialogContext';
import { useContext } from 'react';
import { ClipTypography } from '@clipmx/clip-storybook/dist/components/Typography/Typography';
import { ClipButton } from '@clipmx/clip-storybook/dist/components/Button/Button';
import { useTracking } from '../../../hooks/useTracking';

export const useDetailCredentialView = () => {
  const history = useHistory();
  const theme = useTheme();
  const { state = {} } = useLocation();
  const { nameCredential, apiKeyCredential, apiSecretCredential, typeCredential, createdAt } = state;
  const { dialogActions } = useContext(DialogContext);
  const { analitics, getDeviceType, merchant } = useTracking();

  const isMobil = useMediaQuery(theme.breakpoints.down('xs'));

  const CloseModalReturn = () => {
    dialogActions.closeDialog('BackToCredentialsList');
  };
  const ReturToList = () =>
    history.push({
      pathname: `/credentials`,
    });

  return {
    props: {
      isMobil,
      nameCredential,
      apiKeyCredential,
      apiSecretCredential,
      createdAt,
      typeCredential,
    },
    methods: {
      BackToList: (isReturnShowSecretKey = false) => {
        analitics.trackEvent({
          event: isReturnShowSecretKey ? 'Credentials Created Viewed' : 'Credential Info Viewed',
          properties: {
            navigation: 'back',
            merchant: merchant,
            device: getDeviceType(),
            environment: typeCredential === 'prod' ? 'production' : 'sandbox',
            action: isReturnShowSecretKey ? 'credential_created' : undefined,
            credential_name: nameCredential,
          },
        });

        if (isReturnShowSecretKey === false) {
          ReturToList();
          return;
        }

        analitics.identifyPage({
          category: 'Developer Portal',
          name: 'Credentials Created Back Modal Opened',
          properties: {
            merchant: merchant,
            device: getDeviceType(),
          },
        });

        dialogActions.registerDialog('BackToCredentialsList', {
          open: true,
          type: 'form',
          maxWidth: 'xl',
          title: '¿Quieres salir?',
          children: (
            <div className="container-action-confirm-modal" data-testid="modal-back-to-credentials">
              <ClipTypography
                variant="body1"
                fontWeight="regular"
                className="description"
                data-testid="title-modal-back-to-credentials"
              >
                Una vez que lo hagas, esta clave secreta ya no estará disponible. Asegúrate de guardarla en un lugar
                seguro.
              </ClipTypography>
              <div className="region-actions">
                <span
                  className="cancelar"
                  onClick={() => {
                    analitics.trackEvent({
                      event: 'Credentials Created Back Modal Viewed',
                      properties: {
                        merchant: merchant,
                        device: getDeviceType(),
                        environment: typeCredential === 'prod' ? 'production' : 'sandbox',
                        navigation: 'cancel',
                      },
                    });
                    CloseModalReturn();
                  }}
                  data-testid="btn-cancelar-modal-back-to-credentials"
                >
                  Cancelar
                </span>
                <ClipButton
                  className="create"
                  variant="contained"
                  color="primary"
                  data-testid="cta-modal-back-to-credentials"
                  onClick={() => {
                    analitics.trackEvent({
                      event: 'Credentials Created Back Modal Viewed',
                      properties: {
                        merchant: merchant,
                        device: getDeviceType(),
                        environment: typeCredential === 'prod' ? 'production' : 'sandbox',
                        navigation: 'exit',
                      },
                    });
                    CloseModalReturn();
                    ReturToList();
                  }}
                >
                  Salir
                </ClipButton>
              </div>
            </div>
          ),
          name: 'BackToCredentialsList',
        });
      },
    },
  };
};
