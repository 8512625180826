export const app = {
  // App
  env: process.env.REACT_APP_ENV,
  loginToken: process.env.REACT_APP_LOGIN_TOKEN,
  signInCookieDomain: process.env.REACT_APP_SIGN_IN_COOKIE_DOMAIN,
  apiUrl1: process.env.REACT_APP_API_URL,
  apiUrl2: process.env.REACT_APP_API_URL2,
  reactAppUrl: process.env.REACT_APP_PUBLIC_URL,
  reactAppCookieDomain: process.env.REACT_APP_COOKIEDOMAIN,
  // external sites
  merchantDashboard: process.env.REACT_APP_MERCHANT_DASHBOARD,
  docSite: process.env.REACT_APP_DOC_SITE,
  documentationSite: process.env.REACT_APP_DOCUMENTATION_PORTAL_SITE,
  techRefSite: process.env.REACT_APP_TEC_REF_SITE,
  genApiKeyDoc: process.env.REACT_APP_GENERATE_API_KEY_DOC,
  webHookSiteDoc: process.env.REACT_APP_WEBHOOKS_DOC_SITE,
  devPortalDocSite: process.env.REACT_APP_DEVELOPER_PORTAL_DOC_SITE,
  // authentication
  merchantTokens: process.env.REACT_APP_MERCHANT_TOKENS,
  // Launch Darkly
  launchDarklyKey: process.env.REACT_APP_LAUNCH_DARKLY_KEY,
  // CleverTap
  clevertapApiKey: process.env.REACT_APP_CLEVERTAP_API_KEY,
  // Debug MSW
  debugMSW: process.env.REACT_APP_DEBUG_MSW,

  // SDK Download Links
  SDKBucket: process.env.REACT_APP_AMAZON_S3_BUCKET_SDK,
  //
  appToken: process.env.REACT_APP_ACCESS_TOKEN,
  appMetricKey: process.env.REACT_APP_METRIC_SECRET_KEY,
};

const getConfigValue = key => app[key];

export default getConfigValue;
