import { useContext, useEffect, useState } from 'react';
import { DialogContext } from '@clipmx/clip-storybook/dist/components/Dialog/dialogContext';
import { useHistory } from 'react-router-dom';
import { AlertContext } from '../../Alert';
import { initialize } from 'redux-form';
import { PRODUCTIVE_MODE, SANDBOX_MODE } from '../../../../common/authorizationModeType';
import { useTracking } from '../../../../hooks/useTracking';

export const useNewCredential = ({ typeCredential, createApiApplication, dispatch, apiKey }) => {
  const [inputNameCredential, setInputNameCredential] = useState({
    value: '',
    error: '',
  });
  const [sendRequest, setSendRequest] = useState(false);
  const { dialogActions } = useContext(DialogContext);
  const { openAlert } = useContext(AlertContext);
  const history = useHistory();
  const { analitics, getDeviceType, merchant } = useTracking();

  /*
   * Validation rules for the credential name. Regular expressions are used that are validated sequentially
   * and if any condition does not meet, the error message is displayed. To add one more you have to consider
   * the order of where it is placed.
   */
  const RULES = {
    /* Validation rules for credential name. Regular expressions are used. */
    notEmpty: {
      regex: /^.{1}?/,
      error: 'Este campo es obligatorio.',
      keyError: 'empty_name',
    },
    threerOrMore: {
      text: '3 caracteres o más',
      regex: /^.{3}?/,
      error: 'El nombre debe llevar 3 caracteres o más.',
      keyError: 'minimum_characters_name',
    },
    noSpecialCharacters: {
      text: 'Sin caracteres especiales',
      regex: /^[a-zA-Z0-9\u00E0-\u00FC\s]+$/,
      error: 'El nombre no puede llevar caracteres especiales.',
      keyError: 'special_characters_name',
    },
    /**Validate that there are no spaces in the middle of the word */
    /*noSpaces: {
      text: 'Sin espacios',
      regex: /\b^(?!.* )\w+\b/,
      error: 'El nombre no puede llevar espacios.',
    }, */
    upToOneHundredCharacters: {
      regex: /^[\w\u00E0-\u00FC\s]{3,100}$/,
      error: 'El nombre debe tener menos de 100 caracteres.',
      keyError: 'maximum_character_name',
    },
  };

  const EVALUATIONS = Object.keys(RULES).reduce((accumulator, currentValue) => {
    const _regex = RULES[currentValue].regex;
    return {
      ...accumulator,
      [currentValue]: _regex.test(inputNameCredential.value.trim()),
    };
  }, {});

  const getError = (propError = 'error') => {
    let error = '';
    Object.keys(RULES).some(key => {
      if (EVALUATIONS[key] === false) {
        error = RULES[key][propError];
        return true;
      }
      return false;
    });
    return error;
  };

  const submitData = ({ appName }) => {
    const mode = typeCredential === 'prod' ? PRODUCTIVE_MODE : SANDBOX_MODE;
    return new Promise(() => {
      return createApiApplication(
        appName,
        () => {
          analitics.trackEvent({
            event: 'Credentials Created Viewed',
            properties: {
              action: 'credential_created',
              notification: `created_credential_success`,
              merchantInfo: merchant,
              device: getDeviceType(),
              environment: typeCredential === 'prod' ? 'production' : 'sandbox',
              credential_name: appName,
            },
          });
          setSendRequest(false);
          openAlert({ severity: 'success', message: `Creaste la credencial ${inputNameCredential.value}.` });
          dialogActions.closeDialog('ApplicationName');
        },
        () => {
          analitics.trackEvent({
            event: 'Credentials Created Viewed',
            properties: {
              action: 'credential_created',
              notification: `create_backend_error`,
              merchantInfo: merchant,
              device: getDeviceType(),
              environment: typeCredential === 'prod' ? 'production' : 'sandbox',
              credential_name: appName,
            },
          });
          setSendRequest(false);
          openAlert({ severity: 'error', message: 'Hubo un error al crear la credencial. Intentalo más tarde.' });
          dialogActions.closeDialog('ApplicationName');
        },
        mode
      );
    });
  };

  useEffect(() => {
    dispatch(initialize('createApiKeyForm', { appName: '' }));
  }, [dispatch]);

  useEffect(() => {
    if (apiKey?.item) {
      const nameCredential = apiKey?.item?.name;
      const apiKeyCredential = apiKey?.item?.api_key;
      const apiSecretCredential = apiKey?.item?.api_secret;
      const createdAt = apiKey?.item?.created_at;
      history.push({
        pathname: `/credentials/${apiKeyCredential}`,
        state: { nameCredential, apiKeyCredential, apiSecretCredential, typeCredential, createdAt },
      });
    }
    // eslint-disable-next-line
  }, [apiKey]);

  useEffect(() => {
    if (analitics.metrics) {
      analitics.identifyPage({
        category: 'Developer Portal',
        name: 'Credentials Create Modal Opened',
        properties: {
          merchant: merchant,
          device: getDeviceType(),
        },
      });
    }
  }, [analitics]);

  return {
    props: {
      inputNameCredential,
      EVALUATIONS,
      RULES,
      sendRequest,
      isError:
        (inputNameCredential.error !== '' && inputNameCredential.value !== '') ||
        (inputNameCredential.error !== '' && inputNameCredential.value === ''),
      rulesKeysToRender: Object.keys(RULES).reduce((accumulator, currentValue) => {
        const text = RULES[currentValue]?.text;
        if (text) {
          return [...accumulator, currentValue];
        }
        return accumulator;
      }, []),
    },
    methods: {
      onChange: ({ target: { value } }) => {
        setInputNameCredential({
          value,
          error: '',
        });
      },
      onCloseDialog: () => {
        const appName = inputNameCredential.value.trim();
        dialogActions.closeDialog('ApplicationName');
        analitics.trackEvent({
          event: 'Credential Create Modal Viewed',
          properties: {
            action: 'create_credential',
            navigation: `cancel_button`,
            merchantInfo: merchant,
            device: getDeviceType(),
            environment: typeCredential === 'prod' ? 'production' : 'sandbox',
            credential_name: appName,
            conditions_met: false,
          },
        });
      },
      onClickCreate: async () => {
        const invalidText = Object.values(EVALUATIONS).some(evaluation => evaluation === false);
        const appName = inputNameCredential.value.trim();
        if (invalidText) {
          setInputNameCredential({ ...inputNameCredential, error: getError() });
          analitics.trackEvent({
            event: 'New Credential Modal Filled',
            properties: {
              action: 'create_credential',
              navigation: `create_button`,
              error_message: getError('keyError'),
              merchantInfo: merchant,
              device: getDeviceType(),
              environment: typeCredential === 'prod' ? 'production' : 'sandbox',
              credential_name: appName,
              conditions_met: false,
            },
          });
        } else {
          setSendRequest(true);
          submitData({ appName });
          analitics.trackEvent({
            event: 'Credential Create Modal Viewed',
            properties: {
              action: 'create_credential',
              navigation: `create_button`,
              merchantInfo: merchant,
              device: getDeviceType(),
              environment: typeCredential === 'prod' ? 'production' : 'sandbox',
              credential_name: appName,
              conditions_met: true,
            },
          });
        }
      },
    },
  };
};
