import getConfigValue from './config/enviromentVariables';

export const original = [
  {
    name: 'Developers Dashboard',
    path: '/dashboard',
    icon: 'dashboard',
  },
  {
    name: 'Multilevel',
    icon: 'trending_up',
    items: [
      {
        name: 'Level 1',
        icon: 'list',
        items: [
          {
            name: 'Item 1',
            path: '/charts/victory-charts',
            iconText: '1',
          },
          {
            name: 'Item 2',
            path: '/charts/react-vis',
            iconText: '2',
          },
          {
            name: 'Item 3',
            path: '/charts/recharts',
            iconText: '3',
          },
          {
            name: 'Item 4',
            path: '/charts/echarts',
            iconText: '4',
          },
        ],
      },
      {
        name: 'Level 2',
        icon: 'list',
        items: [
          {
            name: 'Item 1',
            path: '/charts/victory-charts',
            iconText: '1',
          },
          {
            name: 'Item 2',
            path: '/charts/react-vis',
            iconText: '2',
          },
          {
            name: 'Item 3',
            path: '/charts/recharts',
            iconText: '3',
          },
          {
            name: 'Item 4',
            path: '/charts/echarts',
            iconText: '4',
          },
        ],
      },
    ],
  },
  {
    name: 'Utilities',
    icon: 'format_list_bulleted',
    items: [
      {
        name: 'Color',
        path: '/utilities/color',
        iconText: 'C',
      },
      {
        name: 'Spacing',
        path: '/utilities/spacing',
        iconText: 'S',
      },
      {
        name: 'Typography',
        path: '/utilities/typography',
        iconText: 'T',
      },
      {
        name: 'Display',
        path: '/utilities/display',
        iconText: 'D',
      },
    ],
  },
  {
    name: 'Sessions',
    icon: 'trending_up',
    items: [
      {
        name: 'Sign in',
        iconText: 'SI',
        path: '/session/signin',
      },
      {
        name: 'Sign up',
        iconText: 'SU',
        path: '/session/signup',
      },
      {
        name: 'Forgot password',
        iconText: 'FP',
        path: '/session/forgot-password',
      },
      {
        name: 'Error',
        iconText: '404',
        path: '/session/404',
      },
    ],
  },

  {
    name: 'UI Kits',
    icon: 'favorite',
    badge: { value: '50+', color: 'secondary' },
    items: [
      {
        name: 'Auto Complete',
        path: '/material/autocomplete',
        iconText: 'A',
      },
      {
        name: 'Buttons',
        path: '/material/buttons',
        iconText: 'B',
      },
      {
        name: 'Checkbox',
        path: '/material/checkbox',
        iconText: 'C',
      },
      {
        name: 'Dialog',
        path: '/material/dialog',
        iconText: 'D',
      },
      {
        name: 'Expansion Panel',
        path: '/material/expansion-panel',
        iconText: 'E',
      },
      {
        name: 'Form',
        path: '/material/form',
        iconText: 'F',
      },
      {
        name: 'Icons',
        path: '/material/icons',
        iconText: 'I',
      },
      {
        name: 'Menu',
        path: '/material/menu',
        iconText: 'M',
      },
      {
        name: 'Progress',
        path: '/material/progress',
        iconText: 'P',
      },
      {
        name: 'Radio',
        path: '/material/radio',
        iconText: 'R',
      },
      {
        name: 'Switch',
        path: '/material/switch',
        iconText: 'S',
      },
      {
        name: 'Slider',
        path: '/material/slider',
        iconText: 'S',
      },
      {
        name: 'Snackbar',
        path: '/material/snackbar',
        iconText: 'S',
      },
      {
        name: 'Table',
        path: '/material/table',
        iconText: 'T',
      },
    ],
  },

  {
    name: 'Map',
    icon: 'add_location',
    path: '/map',
  },
];

export const navigations = [
  {
    name: 'Documentación',
    path: getConfigValue('documentationSite'),
    clipIcon: 'manual',
  },
  {
    name: 'Referencia Técnica',
    path: getConfigValue('techRefSite'),
    clipIcon: 'code',
  },
  {
    name: 'Panel de Control',
    path: getConfigValue('merchantDashboard'),
    clipIcon: 'reader',
    reload: true,
  },
];

export const dashboardNavigations = [
  {
    name: 'Credenciales',
    path: '/credentials',
    icon: 'control_camera',
    nameMetric: 'credentials',
  },
  {
    name: 'Postback Webhook',
    path: '/webhooks',
    customIcon: 'webhook',
    nameMetric: 'webhooks',
  },
  /* {
    name: 'SDK',
    customIcon: 'code',
    items: [
      {
        name: 'SDK para iOS',
        path: '/sdk/ios',
      },
      {
        name: 'Proyecto demo Android',
        path: '/sdk/demo-project-android',
      },
      {
        name: 'Proyecto demo iOS',
        path: '/sdk/demo-project-ios',
      },
    ],
  }, */
  {
    name: 'Documentación',
    path: getConfigValue('documentationSite'),
    clipIcon: 'manual',
    isExternalLink: true,
    nameMetric: 'documentation',
  },
  {
    name: 'Panel de Control',
    path: getConfigValue('merchantDashboard'),
    customIcon: 'panel',
    isExternalLink: true,
    replaceHistory: true,
    nameMetric: 'control_panel',
  },
];

export default navigations;
