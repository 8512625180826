import { createContext, useContext, useReducer } from 'react';
import getConfigValue from '../config/enviromentVariables';
import { AnalitycSegment } from '../common/AnalitycSegment.class';

export const NEW_METRIC = 'NEW_METRIC';

const MetricContext = createContext(null);
const MetricDispatchContext = createContext(null);

export function MetricProvider({ children }) {
  const [metric, dispatch] = useReducer(metricReducer, initialMetric);

  return (
    <MetricContext.Provider value={metric}>
      <MetricDispatchContext.Provider value={dispatch}>{children}</MetricDispatchContext.Provider>
    </MetricContext.Provider>
  );
}

export function useMetric() {
  return useContext(MetricContext);
}

export function useMetricDispatch() {
  return useContext(MetricDispatchContext);
}

function metricReducer(data, action) {
  const { type, user } = action;
  switch (type) {
    case NEW_METRIC: {
      let metric = null;
      try {
        metric = new AnalitycSegment(getConfigValue('appMetricKey'));
        console.log('£££££ METRIC', metric);
        metric.identifyUser({
          id: user.id,
          traits: {
            name: user.name,
          },
        });
      } catch (error) {
        metric = null;
        console.error('Error when initializing the metrics');
      }
      return metric;
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

const initialMetric = {};
