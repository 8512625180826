import { useSelector } from 'react-redux';
import { useMetric, useMetricDispatch } from '../providers/MetricContext';
import DeviceDetector from 'device-detector-js';
import { useState } from 'react';

export const useTracking = () => {
  const user = useSelector(state => state.session.merchantInfo);
  const analitics = useMetric();
  const analiticsDispatch = useMetricDispatch();
  const [deviceDetector] = useState(new DeviceDetector());

  const getDeviceType = () => {
    let device = null;
    try {
      const info = deviceDetector.parse(navigator.userAgent);
      device = info?.device?.type;
    } catch (error) {}
    return device ?? '';
  };

  return {
    merchant: user?.merchant ?? {},
    analitics,
    analiticsDispatch,
    getDeviceType,
  };
};
