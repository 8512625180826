import { AnalyticsBrowser } from '@segment/analytics-next';
import { MockAnalyticsBrowser } from './MockAnalyticsBrowser.class';

export class AnalitycSegment {
  constructor(apiKey) {
    if (!apiKey || apiKey.length === 0 || apiKey === 'test' || apiKey === 'develop') {
      this.metrics = new MockAnalyticsBrowser();
    } else {
      this.metrics = AnalyticsBrowser.load({ writeKey: apiKey });
    }
  }

  trackEvent(data) {
    this.metrics.track(data.event, { ...data.properties });
  }

  identifyUser(user) {
    this.metrics.identify(user.id, user.traits, () => this.callbackAlias(user));
  }

  identifyPage(event) {
    this.metrics.page(event.category, event.name, event.properties);
  }

  resetMetrics() {
    this.metrics.reset();
  }

  callbackAlias(user) {
    if (user.id !== undefined) this.metrics.alias(user.id, () => console.log('Alias ready!'));
  }
}
