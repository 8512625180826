export class MockAnalyticsBrowser {
  track(event, properties) {
    console.log('Mock Analytics: Track event -', event, properties);
  }

  identify(id, traits, callback) {
    console.log('Mock Analytics: Identify user -', id, traits, callback);
  }

  page(category, name, properties) {
    console.log('Mock Analytics: Identify page -', category, name, properties);
  }

  reset() {
    console.log('Mock Analytics: Reset id');
  }

  alias(id, callback) {
    console.log('Mock Analytics: Alias user -', id, callback);
  }
}
