import { useContext, useEffect, useState } from 'react';
import { DialogContext } from '@clipmx/clip-storybook/dist/components/Dialog/dialogContext';
import { CleverTap, EVENTS } from '../../../events';
import { AlertContext } from '../Alert';
import Icons from '../Icons';
import { ClipTypography } from '@clipmx/clip-storybook/dist/components/Typography/Typography';
import { ClipButton } from '@clipmx/clip-storybook/dist/components/Button/Button';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { PRODUCTIVE_MODE, SANDBOX_MODE } from '../../../common/authorizationModeType';
import { useTracking } from '../../../hooks/useTracking';

export const useDetailCredential = ({
  apiSecretCredential,
  apiKeyCredential,
  deleteApiKey,
  nameCredential,
  typeCredential,
}) => {
  const { dialogActions } = useContext(DialogContext);
  const history = useHistory();
  const [isShowKeySecret, setIsShowKeySecret] = useState(false);
  const { analitics, getDeviceType, merchant } = useTracking();
  const isFlowNewCredential = apiSecretCredential !== '';

  const { openAlert } = useContext(AlertContext);

  const copyClipboard = (type, data) => {
    analitics.trackEvent({
      event: isFlowNewCredential ? 'Credentials Created Viewed' : 'Credential Info Viewed',
      properties: {
        api_key_copied: type === 'apiKey' ? true : false,
        secret_key_copied: type === 'secretKey' ? true : false,
        show_secret_key: isShowKeySecret,
        merchant: merchant,
        device: getDeviceType(),
        environment: typeCredential === 'prod' ? 'production' : 'sandbox',
        action: isFlowNewCredential ? 'credential_created' : undefined,
        credential_name: nameCredential,
      },
    });
    try {
      navigator.clipboard.writeText(data);
      openAlert({ severity: 'success', message: `Copiaste la clave ${type === 'apiKey' ? 'API' : 'secreta'}.` });
    } catch (error) {
      openAlert({ severity: 'error', message: 'Hubo un error al copiar la clave. Por favor inténtalo de nuevo.' });
    }
  };

  const processResultRemoveCredential = (isSuccessProcess = true, callbackCloseModal = () => {}) => {
    analitics.trackEvent({
      event: isFlowNewCredential ? 'Credentials Created Viewed' : 'Credential Info Viewed',
      properties: {
        notification: isSuccessProcess ? 'delete_success' : 'delete_backend_error',
        merchant: merchant,
        device: getDeviceType(),
        environment: typeCredential === 'prod' ? 'production' : 'sandbox',
        action: 'credential_deleted',
      },
    });

    dialogActions.closeDialog('DeleteApiKeyForm');
    openAlert({
      severity: isSuccessProcess ? 'success' : 'error',
      message: isSuccessProcess
        ? `Eliminaste la credencial ${nameCredential}.`
        : 'Hubo un error al eliminar la credencial. Intentalo más tarde',
    });
    if (isSuccessProcess) {
      history.push('/credentials');
    }
    callbackCloseModal();
  };

  const ConfirmModal = () => {
    const [isProcessDelete, setIsProcessDelete] = useState(false);
    const mode = typeCredential === 'prod' ? PRODUCTIVE_MODE : SANDBOX_MODE;

    useEffect(() => {
      if (analitics.metrics) {
        analitics.identifyPage({
          category: 'Developer Portal',
          name: 'Credentials Delete Modal Opened',
          properties: {
            merchant: merchant,
            device: getDeviceType(),
          },
        });
      }
    }, [analitics]);

    return (
      <div className="container-action-delete-modal" data-testid="modal-delete-credential">
        <ClipTypography
          variant="body1"
          fontWeight="regular"
          className="description"
          data-testid="title-modal-delete-credential"
        >
          {`¿Deseas eliminar la credencial ${nameCredential}?`}
        </ClipTypography>
        <div className="region-actions">
          <span
            className="cancelar"
            onClick={() => {
              analitics.trackEvent({
                event: 'Credentials Delete Modal Viewed',
                properties: {
                  navigation: 'back_button',
                  merchant: merchant,
                  device: getDeviceType(),
                  environment: typeCredential === 'prod' ? 'production' : 'sandbox',
                  application_name: nameCredential,
                },
              });
              dialogActions.closeDialog('DeleteApiKeyForm');
            }}
            data-testid="button-return-modal-delete-credential"
          >
            Regresar
          </span>
          <ClipButton
            className="delete"
            variant="contained"
            color="primary"
            disabled={isProcessDelete}
            data-testid="cta-modal-delete-credential"
            onClick={async () => {
              analitics.trackEvent({
                event: 'Credentials Delete Modal Viewed',
                properties: {
                  navigation: 'delete_button',
                  merchant: merchant,
                  device: getDeviceType(),
                  environment: typeCredential === 'prod' ? 'production' : 'sandbox',
                  application_name: nameCredential,
                },
              });
              setIsProcessDelete(true);
              await deleteApiKey(
                apiKeyCredential,
                () => processResultRemoveCredential(true, setIsProcessDelete),
                () => processResultRemoveCredential(false, setIsProcessDelete),
                mode
              );
            }}
          >
            {isProcessDelete ? (
              <CircularProgress color="secundary" size={20} className="circular-progress" />
            ) : (
              'Eliminar'
            )}
          </ClipButton>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (analitics.metrics) {
      analitics.identifyPage({
        category: 'Developer Portal',
        name: isFlowNewCredential ? 'Credentials Created Opened' : 'Credential Info Opened',
        properties: {
          merchant: merchant,
          device: getDeviceType(),
        },
      });
    }
  }, [analitics]);

  return {
    props: {
      isShowKeySecret,
    },
    methods: {
      showKeySecret: () => {
        analitics.trackEvent({
          event: 'Credentials Created Viewed',
          properties: {
            api_key_copied: false,
            secret_key_copied: false,
            show_secret_key: !isShowKeySecret,
            merchant: merchant,
            device: getDeviceType(),
            environment: typeCredential === 'prod' ? 'production' : 'sandbox',
            action: 'credential_created',
            credential_name: nameCredential,
          },
        });
        setIsShowKeySecret(!isShowKeySecret);
      },
      deleteCredential: () => {
        analitics.trackEvent({
          event: isFlowNewCredential ? 'Credentials Created Viewed' : 'Credential Info Viewed',
          properties: {
            navigation: 'delete_button',
            merchant: merchant,
            device: getDeviceType(),
            environment: typeCredential === 'prod' ? 'production' : 'sandbox',
            application_name: nameCredential,
          },
        });

        dialogActions.registerDialog('DeleteApiKeyForm', {
          open: true,
          type: 'form',
          minWidth: 'xl',
          title: 'Eliminar credencial',
          children: <ConfirmModal />,
          name: 'DeleteApiKeyForm',
        });
      },
    },
    microcomponents: {
      Legend: (
        <p data-testid="legend-detail">
          {isFlowNewCredential ? (
            <>
              Recuerda usar las credenciales correctas dependiendo del ambiente en el que estés trabajando. Si olvidas
              tu clave secreta, debes crear una credencial nueva.
            </>
          ) : (
            <>
              Por seguridad tu clave secreta <b>ya no esta disponible</b>. Si la olvidas o extravias, deberas crear una
              credencial nueva.
            </>
          )}
        </p>
      ),
      IconCopy: ({ type, data }) => (
        <div className="region-copy">
          <Icons type="clipIcon" iconName="copy" className="icon-copy" onClick={() => copyClipboard(type, data)} />
        </div>
      ),
    },
  };
};
