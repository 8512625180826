import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import makeStyles from '@clipmx/clip-storybook/dist/theme/clipMakeStyles';

import Grid from '@clipmx/clip-storybook/dist/components/Grid';
import Typography from '@clipmx/clip-storybook/dist/components/Typography';
import CircularProgress from '@clipmx/clip-storybook/dist/components/CircularProgress';

import WebHookCard from '../../common/WebHookCard';

import { getPostbackInfo } from '../../../redux/actions/webhooks';
import { useTracking } from '../../../hooks/useTracking';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  container: {
    flex: 1,
    margin: 24,
    padding: '0 4px',
  },
  header: {
    fontSize: 24,
    fontWeight: 600,
    marginBottom: 24,
  },
}));

/**
 * View for the /webhook route
 * @name WebHooks
 */
const WebHooks = () => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state?.webhooks?.loading);
  const classes = useStyles();
  const { analitics, getDeviceType, merchant } = useTracking();

  useEffect(() => {
    dispatch(getPostbackInfo());
  }, [dispatch]);

  useEffect(() => {
    if (analitics.metrics) {
      analitics.identifyPage({
        category: 'Developer Portal',
        name: 'Webhooks Opened',
        properties: {
          merchant: merchant,
          device: getDeviceType(),
        },
      });
    }
  }, [analitics]);

  return (
    <div data-testid="webhooks-page-container" className={classes.container}>
      <Typography className={classes.header} variant="h3" component="h3" gutterBottom>
        Postback Webhooks
      </Typography>
      <Grid container spacing={0} className={classes.root}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          {loading ? <CircularProgress color="primary" /> : <WebHookCard />}
        </Grid>
      </Grid>
    </div>
  );
};

WebHooks.propTypes = {
  classes: PropTypes.object,
};

export default WebHooks;
